import React, { Component } from "react";

import { 
  CircularProgress,
  Typography
} from '@material-ui/core';

import {
  Modal,
  ModalBody,
  ModalFooter,
  Row,
  ButtonGroup,
  Button,
  Input,
  Col
} from "reactstrap";

import MUIDataTable from "mui-datatables";
import salesOrderApi from "../../../api/SalesOrder";
import apiUtil from "../../../api/apiUtil";

class AddItemDialog extends Component {
  constructor(props) {
    super(props);
    
    let modal = ((props.modal === true || props.modal === false) ? props.modal : false);

    this.state = {
      modal: modal,
      isLoading: false,
      tableState: {
        page: 1,
        rowsPerPage: 10,
        sortOrder: {}
      },
      searchTerm: '',
      items: [],
      totalItems: 0,
      selectedItems: []
    };

  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.modal !== this.state.modal) {
      if(nextProps.modal){ this.searchItem(); }
      this.setState({
        modal: nextProps.modal
      });
    }
  }

  handleClose = () => {
    this.setState({
      modal: false
    }, () => {
      if (this.props.onClose) {
        this.props.onClose(true);
      }
    });
  }

  addItem = (index) => {
    let items = this.state.items;
    if (items[index] === undefined) {
      return false;
    }
    items[index].existing = false;
    this.props.addItem(items[index]);
    // delete items[index];
    // this.setState({
    //   items: items
    // });
  }

  searchItem = async () => {
    if (this.props.customer === "" || this.props.customer === "0") {
      apiUtil.toast('Select customer first.', 'error');
      return false;
    }

    /*if (this.state.searchTerm === "") {
      apiUtil.toast('Search term must be filled in', 'error');
      return false;
    }*/

    this.setState({
      isLoading: true
    });

    await salesOrderApi.getDetail({
      page: this.state.tableState.page,
      take: this.state.tableState.rowsPerPage,
      query: this.state.searchTerm,
      customer_id: this.props.customer
    }).then(res => {
      this.setState({
        isLoading: false,
      });
      
      if (res.status !== 200) {
        apiUtil.toast(res.errorMessage, 'error');
        return false;
      } else {
        let items = [];
        res.data.result.forEach(item => {
          items.push(item);
        });
        this.setState({
          items: items,
          totalItems: res.data.total
        });
      }
    })
    .catch(error => apiUtil.toast(error.message, 'error'));
  }

  handleInputChange = (event, rowIndex) => {
    let items = this.state.items;
    if (items[rowIndex] === undefined) {
      return false;
    }

    items[rowIndex][event.name] = event.value;
    this.setState({
      items: items
    });
  }

  handleSearchTerm = event => {
    this.setState({
      searchTerm: event.target.value
    });
  }

  changePage = (page, sortOrder) => {
    let tableState = this.state.tableState;
    tableState.page = page;
    tableState.sortOrder = sortOrder;

    this.setState({
      isLoading: true,
      tableState: tableState
    });

    this.searchItem();
  }

  sort = (page, sortOrder) => {
    let tableState = this.state.tableState;
    tableState.page = page;
    tableState.sortOrder = sortOrder;

    this.setState({
      isLoading: true,
      tableState: tableState
    });

    this.searchItem();
  }

  render () {
    const columns = [
      {
        name: "container_receipt",
        label: "CR/PR/SO"
      },
      {
        name: "description",
        label: "Desc"
      },
      {
        name: "hawb",
        label: "HAWB"
      },
      {
        name: "part_no",
        label: "Part No"
      },
      {
        name: "lot_no",
        label: "Lot No"
      },
      {
        name: "serial_no",
        label: "S/n No"
      },
      {
        name: "created_at",
        label: "Date In",
        options: {
          customBodyRender: (value) => {
            let date = new Date(value);
            return date.toLocaleString('en-US', {year: 'numeric', month: 'long', day: 'numeric'});
          }
        }
      },
      {
        name: "volume",
        label: "Volume"
      },
      {
        name: "quantity",
        label: "Quantity",
        options: {
          customBodyRender: (value, data) => {
            return (
              <div>
                <Input className="form-control" name="quantity" defaultValue={value} type="number" onChange={(e) => this.handleInputChange(e, data.rowIndex)} />
              </div>
            );
          }
        }
      },
      {
        name: "remarks",
        label: "Remarks",
        options: {
          customBodyRender: (value, data) => {
            return (
              <div>
                 <Input className="form-control" type="text" defaultValue={value} name="remarks" onChange={(e) => this.handleInputChange(e, data.rowIndex)} />
              </div>
            );
          }
        }
      },
      {
        name: "id",
        label: "Action",
        options: {
          customBodyRender: (value, data) => {
            return (
              <ButtonGroup>
                <Button color="success" outline={true} onClick={() => this.addItem(data.rowIndex)}>Add</Button>
              </ButtonGroup>
            );
          }
        }
      }
    ];

    const options = {
      selectableRows: 'none',
      rowsPerPage: this.state.tableState.rowsPerPage,
      serverSide: true,
      download: false,
      search: false,
      print: false,
      count: this.state.totalItems,
      onTableChange: (action, tableState) => {
        switch (action) {
          case 'changePage':
            this.changePage(tableState.page + 1, tableState.sortOrder);
            break;
          case 'sort':
            this.sort(tableState.page + 1, tableState.sortOrder);
            break;
          default:
            console.log('action not handled.');
        }
      }
    }

    return(
      <Modal isOpen={this.state.modal} toggle={this.handleClose} size="xl">
        <ModalBody>
          <Row>
            <Col xs="6">
              <Input type="text" className="form-control" placeholder="CR/Serial No/Part No/Lot No/Location" onChange={e => this.handleSearchTerm(e)} />
            </Col>

            <Col xs="6"><Button color="primary" onClick={(e) => this.searchItem(e)}>Search</Button></Col>

            <Col xs="12" className="mt-3">
              <MUIDataTable
                title={
                  <Typography variant="h6">
                    Search Item
                    {this.state.isLoading && <CircularProgress size={24} style={{marginLeft: 15, position: 'relative', top: 4}} />}
                  </Typography>}
                data={this.state.items}
                columns={columns}
                options={options}
              />
            </Col>
          </Row>
        </ModalBody>

        <ModalFooter>
          <Button color="info" size="sm" outline={true} onClick={this.handleClose}>
            Done
          </Button>
        </ModalFooter>
      </Modal>
    )
  }
}

export default AddItemDialog;