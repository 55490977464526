import React, { Component } from 'react';
import { AuthContext } from "../components/Auth/AuthDataProvider";

import { 
  Grid,
  IconButton,
} from '@material-ui/core';

import { withStyles } from '@material-ui/core/styles';

import MenuIcon from '@material-ui/icons/Menu';

import ProfileDropdown from "../components/Header/ProfileDropdown";

const useStyles = (theme) => ({
  menuButton: {
    marginRight: theme.spacing(2),
    // [theme.breakpoints.up('sm')]: {
    //   display: 'none',
    // },
  },
});

class Header extends Component {
  static contextType = AuthContext;

  constructor(props){
    super(props);

    let showHeader = ((props.showHeader === true || props.showHeader === false) ? props.showHeader : true);

    this.state = {
      showHeader: showHeader,
      isLoggedUser: false,
      isDrawerOpen: false,
      profileMenu: null,
    };
  }

  componentDidMount() {
    this.checkIsUserLoggedIn();
  }

  checkIsUserLoggedIn = () => {
    const { authenticated } = this.context;
    this.setState({ 
      isLoggedUser: authenticated
    });
  }

  logout = ()  => {
    const { clearStorage } = this.context;

    this.setState({
      profileMenu: null,
      isDrawerOpen: false
    });

    clearStorage();

    window.location = "/";
  }

  getHeader = () => {
    return <Grid container alignItems={'center'}>
      <Grid item xs={6} lg={'auto'}>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={() => {
            if(this.props.onDrawerToggle){
              this.props.onDrawerToggle();
            }
          }}
          className={this.props.classes.menuButton}
        >
          <MenuIcon style={{
            color: 'white'
          }} />
        </IconButton>
      </Grid>
      <Grid item xs={true}>
        
      </Grid>
      <Grid item xs={'auto'}>
        <ProfileDropdown 
          profileMenu={this.state.profileMenu}
          onLogout={this.logout}
        />
      </Grid>
    </Grid>
  }
  
  render() {
    if(this.state.showHeader){
      return this.getHeader()
    }
  }
}

export default withStyles(useStyles)(Header);
