import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import InOutReport from "./inout";
import MonthlyReport from "./monthly";
import AgingReport from "./aging";

const Report = ({ match }) => (
    <Switch>
      <Route
        path={`${match.url}/in-out`}
        render={props => <InOutReport {...props} />}
      />
      <Route
        path={`${match.url}/monthly-summary`}
        render={props => <MonthlyReport {...props} />}
      />
      <Route
        path={`${match.url}/aging`}
        render={props => <AgingReport {...props} />}
      />
      <Redirect to="/report/in-out" />
    </Switch>
);

export default Report;
