import React, { Component } from "react";

import { 
  Box,
  CircularProgress,
  Typography
} from '@material-ui/core';
import Button from '@material-ui/core/Button';

import MUIDataTable from "mui-datatables";
import { ReactComponent as EditIcon } from '../../assets/img/icons/edit.svg';
import { ReactComponent as DeleteIcon } from '../../assets/img/icons/delete.svg';

import StickyPageHeader from '../../components/PageHeader/StickyPageHeader';
import BreadCrumb from "../../components/Navs/Breadcrumb";
import IconButton from "../../components/Button/IconButton";
import RemoveDialog from '../../components/Dialog/RemoveDialog';

import Select from 'react-select';
import { Row, Label, InputGroup, InputGroupButtonDropdown, DropdownToggle,DropdownMenu, DropdownItem, Input, Card, CardBody, Col } from "reactstrap";

import apiUtil from "../../api/apiUtil";
import customerApi from "../../api/Customer";
import deliveryOrderApi from "../../api/DeliveryOrder";

import moment from "moment";

class DeliveryOrderList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tableState: {
        page: 0,
        count: 1,
        rowsPerPage: 50,
        sortOrder: {}
      },
      data: [],
      isLoading: false,
      customerList: [],
      selectedCustomer: {
        id: '0',
        name: ''
      },
      isFilterOpen: false,
      filter: {
        customerId: null,
        text: 'Choose..',
        field: 'query',
        value: ''
      }
    };

    this.handleCustomerChange = this.handleCustomerChange.bind(this);
    this.handleFilterChange = this.handleFilterChange.bind(this);
  }

    changePage = (page, sortOrder) => {
    let tableState = this.state.tableState;
    tableState.page = page;
    tableState.sortOrder = sortOrder;

    this.setState({
      isLoading: true,
      tableState: tableState
    });

    this.getData();
  }

  sort = (page, sortOrder) => {
    let tableState = this.state.tableState;
    tableState.page = page;
    tableState.sortOrder = sortOrder;

    this.setState({
      isLoading: true,
      tableState: tableState
    });

    this.getData();
  }

  search = (page, query) => {
    let tableState = this.state.tableState;
    tableState.page = page;
    tableState.search = query;

    this.setState({
      isLoading: true,
      tableState: tableState
    });

    this.getData();
  }

  getData = async (customerId = null) => {
    if (customerId === null) {
      if (this.state.selectedCustomer.id === '0') {
        apiUtil.toast('No customer selected.', 'error');
        return false;
      }
      customerId = this.state.selectedCustomer.id;
    }

    this.setState({ isLoading: true });

    let params = {
      customer_id: customerId,
      optional_filter_by: this.state.filter.field,
      optional_query: this.state.filter.value
    };

    await deliveryOrderApi.get(params).then(data => {
      this.setState({
        isLoading: false,
        data: data.data
      });
    }).catch(error => console.log(error));
  }

  getCustomer = async () => {
    await customerApi.get().then(data => {
      if (Array.isArray(data.data)) {
        let customerList = [];

        data.data.forEach((val) => {
          customerList.push({
            value: val.id,
            label: val.company_code,
            name: val.company_name
          });
        });

        this.setState({
          customerList: customerList
        });
      } else {
        apiUtil.toast('Failed to get customer data.', 'error');
      }
    }).catch(error => console.log(error));
  }

  toggleFilter = () => {
    let isFilterOpen = this.state.isFilterOpen;

    this.setState({
      isFilterOpen: !isFilterOpen
    });
  }

  handleCustomerChange = (event) => {
    this.setState({
      selectedCustomer: {
        id: event.value,
        name: event.name
      }
    });

    this.getData(event.value);
  }

  handleFilterChange = (event) => {
    let filter = this.state.filter;
    filter.text = event.target.innerHTML;
    filter.field = event.target.value;

    this.setState({
      filter: filter
    });
  }

  handleFilterFieldChange = (event) => {
    let filter = this.state.filter;
    filter.value = event.target.value;
    
    this.setState({
      filter: filter
    });
  }

  componentDidMount = () => {
    document.title = "All Delivery Order | WMS";
    window.scrollTo(0, 0);
    this.getCustomer();
    // this.getData();
  }

  removeDialog = () => {
    return <RemoveDialog
    modal={this.state.modal}
    onRemove={(isRemove) => {
      if (isRemove) {
        let data = this.state.data.filter(r => r.id !== this.state.modalItem);

        deliveryOrderApi.delete(this.state.modalItem).then(data => {
          console.log(data);
          if (data.data.status === true) {
            apiUtil.toast(data.data.message, 'success');
          } else {
            apiUtil.toast(data.data.message, 'warning');
          }
        }).catch(err => {
          apiUtil.toast(err.message, 'error');
        });

        this.setState({
          data: data
        });
      }

      this.setState({
        modal: false,
        modalItem: null
      });
    }}
    />
  }

  deleteData = id => {
    this.setState({
      modal: true,
      modalItem: id
    });
  }

  render() {
    const columns = [
      {
        name: "reference_no",
        label: "Reference No"
      },
      {
        name: "created_at",
        label: "Date",
        options: {
          customBodyRender: (value) => {
            return moment(value).format('DD-MMM-YYYY');
          }
        }
      },
      {
        name: "end_user_name",
        label: "End User"
      },
      {
        name: "hawb",
        label: "Hawb"
      },
      {
        name: "container_receipt",
        label: "CR/PR/SO"
      },
      {
        name: "id",
        label: "Action",
        options: {
          customBodyRender: (id, data) => {
            return (
              <div className="btn-group">
                <a href={'./edit?id=' + id}>
                  <EditIcon className="fix-icon-color" />
                </a>
                <button type="button" onClick={() => this.deleteData(id)}>
                  <DeleteIcon />
                </button>
              </div>
            );
          }
        }
      }
    ];

    const options = {
      selectableRows: 'none',
      rowsPerPage: this.state.tableState.rowsPerPage,
      rowsPerPageOptions: [10, 15, 50, 100],
      serverSide: false,
      onTableChange: (action, tableState) => {
        switch (action) {
          case 'changePage':
            this.changePage(tableState.page, tableState.sortOrder);
            break;
          case 'sort':
            this.sort(tableState.page, tableState.sortOrder);
            break;
          default:
            console.log('action not handled.');
        }
      },
      onDownload: (buildHead, buildBody, columns, data) => {
          columns.splice(-1,1);
          data.map(function(item, index){ 
            item.data.splice(-1,1); 
            item.data[1] = moment(item.data[1]).format('DD-MMM-YYYY');
          });
          return "\uFEFF" + buildHead(columns) + buildBody(data); 
      }, 
      downloadOptions:{filename: 'Delivery Order - '+this.state.selectedCustomer.name+'.csv', separator: ','}
    }

    return <Box className="dashboard-page">
      <StickyPageHeader>
        <BreadCrumb />
        <h1 className="page-title">Delivery Order</h1>
        <IconButton className="btn-theme btn-theme-1" label="Add New" icon="add" onClick={() => this.props.history.push('./create')} />
      </StickyPageHeader>

      <Card>
        <CardBody>
          <Row>
            <Col md={{size: 6, offset: 2}}>
              <div className="clearfix">
                <Label className="control-label">
                  Choose Company Code*
                </Label>
                <Select className="form-input has-margin no-border" options={this.state.customerList} onChange={(e) => this.handleCustomerChange(e)} />
              </div>

              <div className="clearfix">
                <Label className="control-label">
                  Company Name
                </Label>
                <Input className="form-control has-margin form-input" defaultValue={this.state.selectedCustomer.name} disabled="" readOnly="readonly" />
              </div>

              <div className="clearfix">
                <Label className="control-label">
                  Search By
                </Label>
                <InputGroup className="form-input-group no-border">
                  <InputGroupButtonDropdown addonType="prepend" isOpen={this.state.isFilterOpen} toggle={this.toggleFilter}>
                  <DropdownToggle caret>{this.state.filter.text}</DropdownToggle>
                  <DropdownMenu>
                  <DropdownItem value="query" onClick={this.handleFilterChange}>Choose..</DropdownItem>
                  <DropdownItem value="container_receipt" onClick={this.handleFilterChange}>CR/PR/SO</DropdownItem>
                  <DropdownItem value="reference_no" onClick={this.handleFilterChange}>Ref No</DropdownItem>
                  <DropdownItem value="lot_no" onClick={this.handleFilterChange}>Lot No</DropdownItem>
                  <DropdownItem value="serial_no" onClick={this.handleFilterChange}>Serial No</DropdownItem>
                  <DropdownItem value="hawb" onClick={this.handleFilterChange}>HAWB</DropdownItem>
                  <DropdownItem value="part_no" onClick={this.handleFilterChange}>Part No</DropdownItem>
                  </DropdownMenu>
                  </InputGroupButtonDropdown>
                  <Input placeholder="optional.." onChange={this.handleFilterFieldChange} />
                </InputGroup>
                <Button
                  className="btn-theme form-input-btn"
                  onClick={() => this.getData()} 
                  color="primary"
                  variant="contained">Search</Button>
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
      
      <Card className="pt-0">
        <CardBody>
          <Row>
            <Col md="12">
              <MUIDataTable
                title={
                  <Typography variant="h6">
                    Delivery Order
                    {this.state.isLoading && <CircularProgress size={24} style={{marginLeft: 15, position: 'relative', top: 4}} />}
                  </Typography>}
                data={this.state.data}
                columns={columns}
                options={options}
              />
            </Col>
          </Row>
        </CardBody>
      </Card>
      {this.removeDialog()}
    </Box>;
  }
}

export default DeliveryOrderList;