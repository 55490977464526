import React, { Component } from "react";

import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
} from "reactstrap";

class RemoveDialog extends Component {
  constructor(props) {
    super();
    
    let modal = ((props.modal === true || props.modal === false) ? props.modal : false);

    this.state = {
      modal: modal,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.modal !== this.state.modal) {
      this.setState({
        modal: nextProps.modal
      });
    }
  }

  handleClose = () => {
    this.setState({
      modal: false
    }, () => {
      if(this.props.onRemove){
        this.props.onRemove(false);
      }
    });
  }

  handleRemove = () => {
    this.setState({
      modal: false
    }, () => {
      if(this.props.onRemove){
        this.props.onRemove(true);
      }
    });
  }

  render() {
    return(
      <Modal isOpen={this.state.modal} toggle={this.handleClose}>
        <ModalHeader toggle={this.handleClose}>
          Remove Data
        </ModalHeader>

        <ModalBody>
          Are you sure?
        </ModalBody>

        <ModalFooter>
          <Button color="success" size="sm" outline={true} onClick={this.handleRemove}>
            Yes
          </Button>{" "}
          <Button color="danger" size="sm" outline={true} onClick={this.handleClose}>
            No
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}


export default RemoveDialog;