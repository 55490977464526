import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import SalesOrderList from "./list";
import SalesOrderForm from "./form";

const SalesOrder = ({ match }) => (
    <Switch>
      <Route
        path={`${match.url}/all`}
        render={props => <SalesOrderList {...props} />}
      />
      <Route
        path={`${match.url}/create`}
        render={props => <SalesOrderForm {...props} />}
      />
      <Route
        path={`${match.url}/edit`}
        render={props => <SalesOrderForm {...props} />}
      />
      <Redirect to="/transaction/sales-order/all" />
    </Switch>
);

export default SalesOrder;
