const zoneData = [
  { value: 40, label: 'Central' },
  { value: 42, label: 'East' },
  { value: 45, label: 'Town' },
  { value: 46, label: 'Pandan Loop' },
  { value: 2, label: 'West' },
  { value: 4, label: 'North' },
];

const transportData = [
  { value: 6, label: 'Supply Manpower At Warehouse' },
  { value: 5, label: 'Supply Manpower On Site' },
  { value: 2, label: 'Half Truck' },
  { value: 1, label: 'Full Truck' },
  { value: 4, label: 'Half Van' },
  { value: 3, label: 'Full Van' },
  { value: 7, label: 'Truck Service' },
  { value: 8, label: 'Van Service' },
];

const locationData = [
  {value: 40, label: 'Central'},
  {value: 42, label: 'East'},
  {value: 45, label: 'Town'},
  {value: 46, label: 'Pandan Loop'},
  {value: 2, label: 'West'},
  {value: 4, label: 'North'}
];

export { zoneData, transportData, locationData };