import React, { Component } from "react";

import {
  Modal,
  ModalBody,
  ModalFooter,
  Row,
  Label,
  Button,
  FormGroup,
  Input,
  Col
} from "reactstrap";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from 'react-select';
import { locationData } from '../../../data/misc';

class EditItemDialog extends Component {
  constructor(props) {
    super(props);
    
    let modal = ((props.modal === true || props.modal === false) ? props.modal : false);

    this.state = {
      modal: modal,
      displayDate: '',
      formData: {
        index: '',
        part_no: '',
        description: '',
        lot_no: '',
        serial_no: '',
        quantity: '',
        width: '',
        height: '',
        length: '',
        remarks: '',
        location: '',
        expiry_date: ''
      }
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.modal !== this.state.modal) {
      let formData = nextProps.data;
      let displayDate = '';

      if(formData.expiry_date){ displayDate = new Date(formData.expiry_date); }
      this.setState({
        formData: formData,
        modal: nextProps.modal,
        displayDate: displayDate
      });
    }
  }

  handleClose = () => {
    this.setState({
      modal: false
    }, () => {
      if(this.props.onUpdate){
        this.props.onUpdate(true);
      }
    });
  }

  handleUpdate = () => {
    this.setState({
      modal: false
    }, () => {
      if (this.props.onUpdate){
        this.props.onUpdate(this.state.formData);
      }
    });
  }

  handleInputChange = event => {
    let formState = this.state.formData;
    formState[event.target.name] = event.target.value;

    this.setState({
      formData: formState
    });
  }

  changeDate = date => {
    let formState = this.state.formData;
    formState.expiry_date = date.toLocaleDateString('en-US');

    this.setState({
      formData: formState,
      displayDate: date
    });
  }

  handleLocationChange = event => {
    let formState = this.state.formData;
    formState.location = event.value;
    this.setState({
      formData: formState
    });
  }
  
  setLocationDefault = (location) => {
    let value = {
      value: '',
      label: ''
    };

    for (let index in locationData) {
      if (locationData[index]['value'] === parseInt(location)) {
        value = locationData[index];
        break;
      }
    }

    return value;
  }

  render(){
    return(
      <Modal isOpen={this.state.modal} toggle={this.handleClose} size="lg" className="modal-dialog-centered">
        <ModalBody>
          <Row>
            <Col xs="6">
              <FormGroup>
                <Label className="control-label">Part No*</Label>
                <Input name="part_no" className="form-input form-control" onChange={(e) => this.handleInputChange(e)} value={this.state.formData.part_no} />
              </FormGroup>

              <FormGroup>
                <Label className="control-label">Description*</Label>
                <Input name="description" component="textarea" rows="5" className="form-input form-control" onChange={this.handleInputChange} value={this.state.formData.description} />
              </FormGroup>

              <FormGroup>
                <Label className="control-label">Lot No*</Label>
                <Input name="lot_no" className="form-input form-control" onChange={this.handleInputChange} value={this.state.formData.lot_no} />
              </FormGroup>

              <FormGroup>
                <Label className="control-label">Serial No*</Label>
                <Input name="serial_no" className="form-input form-control" onChange={this.handleInputChange} value={this.state.formData.serial_no} />
              </FormGroup>

              <FormGroup>
                <Label className="control-label">Expiry Date*</Label>
                <div className="form-input">
                  <DatePicker dateFormat="dd/MM/yyyy" className="form-control" selected={this.state.displayDate} onChange={date => this.changeDate(date)} />
                </div>
              </FormGroup>

              <FormGroup>
                <Label className="control-label">Location*</Label>
                <Select className="form-input no-border" options={locationData} onChange={this.handleLocationChange} defaultValue={this.setLocationDefault(this.state.formData.location)} />
              </FormGroup>
            </Col>

            <Col xs="6">
              <FormGroup>
                <Label className="control-label">Quantity*</Label>
                <Input name="quantity" className="form-input form-control" onChange={this.handleInputChange} value={this.state.formData.quantity} />
              </FormGroup>

              <FormGroup>
                <Label className="control-label">Volume*</Label>
                <Input name="length" placeholder="Length (cm)" className="form-input form-control" onChange={this.handleInputChange} value={this.state.formData.length} />
                <Input name="width" placeholder="Width (cm)" className="form-input form-control" onChange={this.handleInputChange} value={this.state.formData.width} />
                <Input name="height" placeholder="Height (cm)" className="form-input form-control" onChange={this.handleInputChange} value={this.state.formData.height} />
              </FormGroup>

              <FormGroup>
                <Label className="control-label">Remarks</Label>
                <Input name="remarks" component="textarea" rows="5" className="form-input form-control" onChange={this.handleInputChange} value={this.state.formData.remarks} />
              </FormGroup>
            </Col>
          </Row>
        </ModalBody>

        <ModalFooter>
          <Button color="success" size="sm" outline={true} onClick={this.handleUpdate}>
            Update
          </Button>
          <Button color="danger" size="sm" outline={true} onClick={this.handleClose}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    )
  }
}

export default EditItemDialog;