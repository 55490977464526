import React, { Component } from "react";

import { Box } from '@material-ui/core';
import { Container, Row, Label, Card, CardBody, Col, Input, Table, ButtonGroup } from "reactstrap";
import * as Yup from "yup";

import StickyPageHeader from '../../components/PageHeader/StickyPageHeader';
import BreadCrumb from "../../components/Navs/Breadcrumb";
import ProgressButton from "../../components/Button/ProgressButton";
import Loading from "react-fullscreen-loading";

import queryString from 'query-string';
import { Formik, Form, Field } from "formik";
import Select from 'react-select';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import apiUtil from '../../api/apiUtil';
import customerApi from "../../api/Customer";
import salesOrderApi from "../../api/SalesOrder";
import moment from "moment";
import EditItemDialog from './Partial/EditItemDialog';
import AddItemForm from "./Partial/AddItemForm";
import { locationData } from '../../data/misc';

import { ReactComponent as EditIcon } from '../../assets/img/icons/edit.svg';
import { ReactComponent as DeleteIcon } from '../../assets/img/icons/delete.svg';

const SalesOrderSchema = Yup.object({
  container_receipt: Yup.string().required('Please input value'),
  hawb: Yup.string(),
  end_user_name: Yup.string(),
  total_kg: Yup.number()
    .typeError('Value must be a number')
    .positive('Value must be greater than zero'),
  total_package: Yup.number()
    .typeError('Value must be a number')
    .positive('Value must be greater than zero'),
  remarks: Yup.string()
});

class SalesOrderForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modal: false,
      editItemData: null,
      dataId: '',
      isLoading: false,
      pageLoading: false,
      customerList: [],
      selectedCustomer: {
        id: '0',
        name: '',
        code: ''
      },
      formData: {
        handling_in_date: new Date(),
        container_receipt: '',
        hawb: '',
        end_user_id: '',
        end_user_name: '',
        total_kg: '',
        total_package: '',
        remarks: ''
      },
      order_details: []
    };

    this.handleCustomerChange = this.handleCustomerChange.bind(this);
  }

  componentDidMount = () => {
    let urlQuery = queryString.parse(window.location.search);
    if (urlQuery.id !== undefined) {
      this.setState({
        dataId: urlQuery.id
      });
      document.title = "Edit Sales Order | Moovaz WMS";
    } else {
      document.title = "Create Sales Order | Moovaz WMS";
    }

    this.setState({
      pageLoading: true,
    });

    window.scrollTo(0, 0);
    localStorage.removeItem('currentItem');
    this.getCustomer();
  }

  getCustomer = async () => {
    await customerApi.get().then(data => {
      if (Array.isArray(data.data)) {
        let customerList = [];

        data.data.forEach((val) => {
          customerList.push({
            value: val.id,
            label: val.company_code,
            name: val.company_name
          });
        });

        this.setState({
          customerList: customerList
        });

        if (this.state.dataId !== "") {
          this.getData(this.state.dataId);
        } else {
          this.setState({
            pageLoading: false,
          });
        }
      } else {
        apiUtil.toast('Failed to get customer data.', 'error');
      }
    }).catch(error => console.log(error));
  }

  handleInputChange = event => {
    let formState = this.state.formData;
    formState[event.target.name] = event.target.value;

    this.setState({
      formData: formState
    });
  }

  handleCustomerChange = (event) => {
    this.setState({
      selectedCustomer: {
        id: event.value,
        name: event.name,
        code: event.label
      }
    });
  }

  handleSubmit = values => {
    if (this.state.order_details.length <= 0) {
      apiUtil.toast('No item in this order.', 'error');
      return false;
    }

    if (this.state.selectedCustomer.id === "") {
      apiUtil.toast('No customer is selected.', 'error');
      return false;
    }

    values.order_details = this.state.order_details.filter(function (el) {
      return el != null;
    });

    values.customer_id = this.state.selectedCustomer.id;
    values.order_branch_id = 1;
    values.handling_in_date = moment(this.state.formData.handling_in_date).format('DD-MM-YYYY');

    values.order_details.map(function(v,k){
      if(v.expiry_date){  v.expiry_date = moment(v.expiry_date).format('DD-MM-YYYY');}
    });

    if (this.state.dataId === "") { // create
      this.setState({
        isLoading: true
      });

      salesOrderApi.create(values).then(data => {
        this.setState({
          isLoading: false,
        });

        if (data.status === 200) {
          apiUtil.toast("Sales order created.", 'success');
          this.props.history.push('../sales-order/all');
        } else {
          apiUtil.toast(data.errorMessage, 'error');
        }
      }).catch(error => 
        apiUtil.toast(error.message, 'error')
      );
    } else { // update
      delete values.end_user_name;
      this.setState({ isLoading: true });
      salesOrderApi.update(this.state.dataId, values).then(data => {
        this.setState({
          isLoading: false,
        });

        if (data.status === 200) {
          apiUtil.toast("Sales order updated.", 'success');
          this.props.history.push('../sales-order/all');
        } else {
          apiUtil.toast(data.errorMessage, 'error');
        }
      });
    }
  }

  changeDate = date => {
    let formData = this.state.formData;
    formData.handling_in_date = date;
    this.setState({
      formData: formData
    });
  }

  editItemDialog = () => {
    return <EditItemDialog
      modal={this.state.modal}
      data={this.state.editItemData}
      onUpdate={(data) => {
        let orderData = this.state.order_details;

        if (typeof data === 'object' && data !== null) {
          let index = data.index;
          data.volume = this.calculateVolume(data.width, data.length, data.height);

          delete data.index;
          
          orderData[index] = data;
        }

        this.setState({
          modal: false,
          editItemData: null,
          order_details: orderData
        });

        localStorage.setItem('currentItem', JSON.stringify(orderData));
      }}
    />
  }

  editItemOrder = index => {
    let orderData = this.state.order_details;
    orderData[index]['index'] = index;
    this.setState({
      modal: true,
      editItemData: orderData[index]
    });
  }

  checkItem = item => {
    if (
      isNaN(parseFloat(item.width)) ||
      isNaN(parseFloat(item.height)) ||
      isNaN(parseFloat(item.length))
    ) {
      return {
        pass: false,
        message: 'Width / Length / Height is not a number'
      };
    }

    return {
      pass: true,
      message: ''
    };
  }

  addItemToOrder = (item) => {
    let oldData = localStorage.getItem('currentItem')?JSON.parse(localStorage.getItem('currentItem')):this.state.order_details;
    if (Array.isArray(item)) {
      let error = false;

      item.forEach(i => {
        let checkItem = this.checkItem(i);
        if (!checkItem['pass']) {
          error = true;
          apiUtil.toast(checkItem['message'], 'error');
          return false;
        }

        i.volume = this.calculateVolume(i.width, i.length, i.height);
        locationData.map(function(v,k){          
          if(typeof i.location == "string" && i.location.toLowerCase() == v.label.toLowerCase()){
            i.location = v.value;            
          } 
        });

        if(i.expiry_date){ let dataDate = i.expiry_date.split("/");
        i.expiry_date = dataDate[1]+"/"+dataDate[0]+"/"+dataDate[2]; }
        

      });

      if (!error) {
        this.setState({
          order_details: item
        });
      }
    } else {
      let checkItem = this.checkItem(item);
      if (!checkItem['pass']) {
        apiUtil.toast(checkItem['message'], 'error');
        return false;
      }

      item.volume = this.calculateVolume(item.width, item.length, item.height);

      this.setState({
        order_details: [...oldData, item]
      })

      localStorage.setItem('currentItem', JSON.stringify([...oldData, item]));
    }
  }

  deleteItemOrder = index => {
    let orders = this.state.order_details;
    if (orders[index]['id']) {
      orders[index]['delete'] = true;
    } else {
      delete orders[index];
      orders.splice(index, 1);
    }
    this.setState({
      order_details: orders
    });
    localStorage.setItem('currentItem', JSON.stringify(orders));
  }

  calculateVolume = (width, length, height) => {
    let val = (parseFloat(width) * parseFloat(length) * parseFloat(height) / 1000000);
    let splitter = val.toString().split(".");
    return splitter.length > 1 && splitter[1].length > 6? val.toFixed(6) : val;
  }

  calculateVolumeTotal = (volume, qty) => { 
    let val = (parseFloat(volume) * parseFloat(qty));
    let splitter = val.toString().split(".");
    return splitter.length > 1 && splitter[1].length > 6? val.toFixed(6) : val;
  }

  calculateVolumeGrandTotal = () => {
    let result = 0;
    this.state.order_details.map(function (item, index) {
      result += (parseFloat(item.volume) *parseFloat(item.quantity));
    });

    let splitter = result.toString().split(".");
    return splitter.length > 1 &&  splitter[1].length > 6? result.toFixed(6) : result;
  }

  calculateQtyTotal = () => {
    let result = 0;
    this.state.order_details.map(function (item, index) {
      result += parseFloat(item.quantity);
    });

    return result;
  }

  getData = (dataId) => {
    salesOrderApi.show(dataId).then(data => {
      if (data.status === 200) {
        this.populateData(data.data.result);
      } else {
        apiUtil.toast(data.message, 'error');
      }
    });
  }

  populateData = (data) => {
    let formData = this.state.formData;
    for (const prop in formData) {
      if (data[prop] !== undefined) {
        if (prop === 'handling_in_date') {
          formData.handling_in_date = new Date(data.handling_in_date);
        } else {
          formData[prop] = data[prop];
        }
      }
    }

    if (data.end_user !== undefined) {
      formData.end_user_id = data.end_user.id;
      formData.end_user_name = data.end_user.end_user;
    }

    let orderDetails = data.order_details;

    this.setState({
      pageLoading: false,
      formData: formData,
      order_details: orderDetails,
      selectedCustomer: {
        id: data.customer.id,
        name: data.customer.company_name,
        code: data.customer.company_code
      }
    });
  }

  render() {
    const theClass = this;
    return (
      <Box className="dashboard-page">
        <Loading loading={this.state.pageLoading} background="#f5f5f5" loaderColor="#e89cae" />
        <StickyPageHeader>
          <BreadCrumb />
          <h1 className="page-title">{this.state.dataId !== "" ? "Update Sales Order" : "Create Sales Order"}</h1>
        </StickyPageHeader>

        <Container>
        <Row>
          <Formik
            enableReinitialize={true}
            initialValues={{
              container_receipt: this.state.formData.container_receipt,
              hawb: this.state.formData.hawb,
              end_user_name: this.state.formData.end_user_name,
              total_kg: this.state.formData.total_kg,
              total_package: this.state.formData.total_package,
              remarks: this.state.formData.remarks
            }}
            validationSchema={SalesOrderSchema}
            onSubmit={this.handleSubmit}>
            {({
              handleSubmit,
              setFieldValue,
              setFieldTouched,
              values,
              errors,
              touched,
              validateForm
            }) => (
              <Form>
                <Col md={{ size: 12 }}>
                  <Card className="card pb-0 mb-1 mt-2">
                    <CardBody>
                      <Row>
                        <Col md="12">
                          <div className="mb-4">
                            <Label className="control-label">
                              Choose Company Code*
                            </Label>
                            <Select value={{label: this.state.selectedCustomer.code, value: this.state.selectedCustomer.id}} className="form-input has-margin no-border" options={this.state.customerList} onChange={(e) => this.handleCustomerChange(e)} />
                          </div>

                          <div className="mb-4">
                            <Label className="control-label">
                              Company Name
                            </Label>
                            <Input className="form-control has-margin form-input" defaultValue={this.state.selectedCustomer.name} disabled="" readOnly="readonly" />
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>

                  <Card>
                    <CardBody>
                      <Row className="mb-2">
                        <Col xs="12" className="mt-3">
                          <Label className="control-label">
                            Date
                          </Label>
                          <div className="form-input">
                          <DatePicker 
                            className="form-control" 
                            dateFormat="dd/MM/yyyy"
                            selected={this.state.formData.handling_in_date} 
                            onChange={date => this.changeDate(date)}
                          />
                          </div>
                        </Col>
                      </Row>

                      <Row className="mb-2">
                        <Col xs="12" className="mt-3">
                          <Label className="control-label">
                            CR/PO/SO*
                          </Label>
                          <Field className="form-control form-input" name="container_receipt" value={this.state.formData.container_receipt || ''} onChange={this.handleInputChange} />
                          {
                            errors.container_receipt && touched.container_receipt ? (
                              <div className="invalid-feedback d-block">
                              {errors.container_receipt}
                              </div>
                              ) : null
                          }
                        </Col>
                      </Row>

                      <Row className="mb-2">
                        <Col xs="12" className="mt-3">
                          <Label className="control-label">
                            HAWB
                          </Label>
                          <Field className="form-control form-input" name="hawb" value={this.state.formData.hawb || ''} onChange={this.handleInputChange} />
                          {
                            errors.hawb && touched.hawb ? (
                              <div className="invalid-feedback d-block">
                              {errors.hawb}
                              </div>
                              ) : null
                          }
                        </Col>
                      </Row>

                      <Row className="mb-2">
                        <Col xs="12" className="mt-3">
                          <Label className="control-label">
                            End User
                          </Label>
                          <Field className="form-control form-input" name="end_user_name" value={this.state.formData.end_user_name || ''} onChange={this.handleInputChange} />
                          {
                            errors.end_user_name && touched.end_user_name ? (
                              <div className="invalid-feedback d-block">
                              {errors.end_user_name}
                              </div>
                              ) : null
                          }
                        </Col>
                      </Row>

                      <Row className="mb-2">
                        <Col xs="12" className="mt-3">
                          <Label className="control-label">
                            Total Weight (kg)
                          </Label>
                          <Field className="form-control form-input" type="number" name="total_kg" value={this.state.formData.total_kg || ''} onChange={this.handleInputChange} placeholder="kg" />
                          {
                            errors.total_kg && touched.total_kg ? (
                              <div className="invalid-feedback d-block">
                              {errors.total_kg}
                              </div>
                              ) : null
                          }
                        </Col>
                      </Row>

                      <Row className="mb-2">
                        <Col xs="12" className="mt-3">
                          <Label className="control-label">
                            Total Packages
                          </Label>
                          <Field className="form-control form-input" name="total_package" value={this.state.formData.total_package || ''} onChange={this.handleInputChange} placeholder="quantity" />
                          {
                            errors.total_package && touched.total_package ? (
                              <div className="invalid-feedback d-block">
                              {errors.total_package}
                              </div>
                              ) : null
                          }
                        </Col>
                      </Row>

                      <Row className="mb-2">
                        <Col xs="12" className="mt-3">
                          <Label className="control-label">
                            Remarks
                          </Label>
                          <Field className="form-control form-input" name="remarks" component="textarea" value={this.state.formData.remarks || ''} onChange={this.handleInputChange} rows="5" />
                          {
                            errors.remarks && touched.remarks ? (
                              <div className="invalid-feedback d-block">
                              {errors.remarks}
                              </div>
                              ) : null
                          }
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>

                  <Card className="mt-2">
                    <CardBody>
                      <Col xs="12">
                        <h4 className="font-weight-bold mb-4">Items in this Order</h4>
                      </Col>
                      <Table className="mb-5">
                        <thead>
                          <tr>
                            <th>No.</th>
                            <th>Description</th>
                            <th>vol(m<sup>3</sup>)</th>
                            <th>Part No.</th>
                            <th>Lot No.</th>
                            <th>Serial No.</th>
                            <th>Qty</th>
                            <th>Expiry Date</th>
                            <th>Total Vol(m<sup>3</sup>)</th>       
                            <th>Action</th>
                          </tr>
                        </thead>

                        <tbody>
                          {this.state.order_details.map(function (item, index) {
                            if (item.delete !== true) {
                              return <tr key={index}>
                                  <td>{index + 1}</td>
                                  <td>{item.description}</td>
                                  <td>{item.volume}</td>
                                  <td>{item.part_no}</td>
                                  <td>{item.lot_no}</td>
                                  <td>{item.serial_no}</td>
                                  <td>{item.quantity}</td>
                                  <td>{item.expiry_date?moment(item.expiry_date).format('DD/MM/YYYY'):""}</td>
                                  <td>{theClass.calculateVolumeTotal(item.volume, item.quantity)}</td>                         
                                  <td>
                                    <ButtonGroup>
                                      <EditIcon className="fix-icon-color btn-icon" title="Edit" onClick={e => theClass.editItemOrder(index)} />
                                      <button title="Delete" type="button" className="btn-icon" onClick={e => theClass.deleteItemOrder(index)}>
                                        <DeleteIcon />
                                      </button>
                                    </ButtonGroup>
                                  </td>
                              </tr>
                            } else return "";
                          })}
                        </tbody>
                        <tfoot>                          
                          <tr>
                            <th colSpan='6' style={{textAlign:'right'}}>Total Qty</th>
                            <th>{theClass.calculateQtyTotal()}</th>
                            <th style={{textAlign:'right'}}>Grand Total Vol (m<sup>3</sup>)</th>
                            <th colSpan='2'>{theClass.calculateVolumeGrandTotal()}</th>
                          </tr>
                        </tfoot>
                      </Table>
                    </CardBody>
                  </Card>

                  <Card className="mt-4">
                    <CardBody>
                      <AddItemForm
                        addItem={(item) => this.addItemToOrder(item)}
                      />
                    </CardBody>
                  </Card>

                  <Card className="mt-4">
                    <ProgressButton 
                    type={'submit'}
                    color={'secondary'}
                    isLoading={this.state.isLoading}>
                      <Box pl={2} pr={2}>Submit</Box>
                    </ProgressButton>
                  </Card>
                </Col>
              </Form>
            )}
          </Formik>
        </Row>
        </Container>
        {this.editItemDialog()}
      </Box>
    );
  }
}

export default SalesOrderForm;