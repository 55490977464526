import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import CustomerList from "./list";
import CustomerForm from "./form";

const Customers = ({ match }) => (
    <Switch>
      <Route
        path={`${match.url}/all-customers`}
        render={props => <CustomerList {...props} />}
      />
      <Route
        path={`${match.url}/create-customer`}
        render={props => <CustomerForm {...props} />}
      />
      <Route
        path={`${match.url}/edit-customer`}
        render={props => <CustomerForm {...props} />}
      />
      <Redirect to="/customers/all-customers" />
    </Switch>
);

export default Customers;
