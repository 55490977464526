import React, { Component } from "react";

import { Box } from '@material-ui/core';
import { Row, Label, Form, FormGroup, Col, Input } from "reactstrap";

import StickyPageHeader from '../../components/PageHeader/StickyPageHeader';
import BreadCrumb from "../../components/Navs/Breadcrumb";
import ProgressButton from "../../components/Button/ProgressButton";

import Select from 'react-select';
import apiUtil from '../../api/apiUtil';
import reportApi from "../../api/Report";
import customerApi from "../../api/Customer";

import AgingReportTable from "./Partial/AgingReportTable";

class AgingReport extends Component {
  constructor(props) {
    super(props);

    this.state = {
      displayDate: new Date(),
      filter: '3',
      isLoading: false,
      customerList: [],
      reportData: null,
      selectedCustomer: {
        id: 0,
        name: ''
      },
    };
  }

  componentDidMount = () => {
    document.title = "Aging Report | WMS";
    window.scrollTo(0, 0);
    this.getCustomer();
  }

  getCustomer = async () => {
    await customerApi.get().then(data => {
      if (Array.isArray(data.data)) {
        let customerList = [];

        data.data.forEach((val) => {
          customerList.push({
            value: val.id,
            label: val.company_code,
            name: val.company_name
          });
        });

        this.setState({
          customerList: customerList
        });
      } else {
        apiUtil.toast('Failed to get customer data.', 'error');
      }
    }).catch(error => console.log(error));
  }

  handleCustomerChange = event => {
    this.setState({
      selectedCustomer: {
        id: event.value,
        name: event.name
      }
    });
  }

  handleFilter = event => {
    this.setState({
      filter: event.target.value
    });
  }

  changeDate = date => {
    this.setState({
      displayDate: date
    });
  }

  generateReport = async (event) => {
    event.preventDefault();
    if (this.state.selectedCustomer.id === 0) {
      apiUtil.toast('Select customer first!', 'error');
      return false;
    }

    this.setState({
      isLoading: true
    });

    await reportApi.getAging({
      customer_id: this.state.selectedCustomer.id,
      expiry: this.state.filter
    }, this.state.filter).then(data => {
      if (data.errorMessage !== "") {
        apiUtil.toast(data.errorMessage, 'error');
        this.setState({
          isLoading: false,
        });
        return false;
      }

      this.setState({
        isLoading: false,
        reportData: data.data.data
      });
    }).catch(error => console.log(error));
  }

  render () {
    return (
      <Box className="dashboard-page">
        <StickyPageHeader>
          <BreadCrumb />
          <h1 className="page-title">Aging Report</h1>
        </StickyPageHeader>

        <Row className="mt-3">
          <Col md={{ size: 8, offset: 2 }}>
            <Form onSubmit={this.generateReport}>
              <FormGroup className="mb-4">
                <Label className="control-label">
                  Choose Company Code*
                </Label>
                <Select className="form-input has-margin no-border" options={this.state.customerList} onChange={(e) => this.handleCustomerChange(e)} />
              </FormGroup>

              <FormGroup className="mb-4 clearfix">
                <Label className="control-label">
                  Company Name
                </Label>
                <Input className="form-control form-input" defaultValue={this.state.selectedCustomer.name} disabled="" readOnly="readonly" />
              </FormGroup>

              <FormGroup className="mb-4 clearfix">
                <Label className="control-label">
                  Expiry Within
                </Label>
                <FormGroup check className="form-input">
                  <Label check>
                    <Input type="radio" defaultValue="3" checked={this.state.filter === '3'} onChange={(e) => this.handleFilter(e)} /> 3 Months
                  </Label>
                  <br />
                  <Label check className="mt-3">
                    <Input type="radio" defaultValue="3-6" checked={this.state.filter === '3-6'} onChange={(e) => this.handleFilter(e)} /> 
                      3-6 Months
                  </Label>
                  <br />
                  <Label check className="mt-3">
                    <Input type="radio" defaultValue="6-9" checked={this.state.filter === '6-9'} onChange={(e) => this.handleFilter(e)} /> 
                      6-9 Months
                  </Label>
                  <br />
                  <Label check className="mt-3">
                    <Input type="radio" defaultValue="9-12" checked={this.state.filter === '9-12'} onChange={(e) => this.handleFilter(e)} /> 
                      9-12 Months
                  </Label>
                  <br />
                  <Label check className="mt-3">
                    <Input type="radio" defaultValue="expired" checked={this.state.filter === 'expired'} onChange={(e) => this.handleFilter(e)} /> 
                      Expired
                  </Label>
                </FormGroup>
              </FormGroup>

              <FormGroup className="mb-4">
                <Label className="control-label">{' '}</Label>
                <div className="form-input">
                  <ProgressButton
                  type={'submit'}
                  color={'secondary'}
                  isLoading={this.state.isLoading}>
                    <Box pl={2} pr={2}>Generate Report</Box>
                  </ProgressButton>
                </div>
              </FormGroup>
            </Form>
          </Col>
        </Row>

        {this.state.reportData !== null &&
          <AgingReportTable reportData={this.state.reportData} />
        }
      </Box>
    );
  }
}

export default AgingReport;