import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import DeliveryOrderList from "./list";
import DeliveryOrderForm from "./form";
import DeliveryOrderCheck from "./check";

const DeliveryOrder = ({ match }) => (
    <Switch>
      <Route
        path={`${match.url}/all`}
        render={props => <DeliveryOrderList {...props} />}
      />
      <Route
        path={`${match.url}/create`}
        render={props => <DeliveryOrderForm {...props} />}
      />
      <Route
        path={`${match.url}/edit`}
        render={props => <DeliveryOrderForm {...props} />}
      />
      <Route
        path={`${match.url}/check`}
        render={props => <DeliveryOrderCheck {...props} />}
      />
      <Redirect to="/transaction/delivery-order/all" />
    </Switch>
);

export default DeliveryOrder;
