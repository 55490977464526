import React, { Component } from "react";

import { Box } from '@material-ui/core';
import { Row, Label, Card, CardTitle, CardBody, Col, Input, Table } from "reactstrap";

import StickyPageHeader from '../../components/PageHeader/StickyPageHeader';
import BreadCrumb from "../../components/Navs/Breadcrumb";

// import { DateRangePicker } from 'react-date-range';

import CustomDateRangePicker from '../../components/CustomFields/CustomDateRangePicker';

// import { addDays } from 'date-fns';
// import 'react-date-range/dist/styles.css';
// import 'react-date-range/dist/theme/default.css';
import Select from 'react-select';
import apiUtil from '../../api/apiUtil';
import customerApi from "../../api/Customer";
import deliveryOrderApi from "../../api/DeliveryOrder";
import moment from "moment";

import { locationData } from '../../data/misc';

class DeliveryCheckForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      items: [],
      dateRange: {
        startDate: moment(),
        endDate: moment(),
      },
      zone_id: 0,
      ref_no: '',
      customerList: [],
      orderRefList: [],
      totalQty: 0,
      totalQtyScanned: 0,
      scannedField: {
        lot_no: '',
        part_no: ''
      },
      selectedCustomer: {
        id: 0,
        name: ''
      },
    };
  }

  componentDidMount = () => {
    document.title = "Check Delivery Order Out | WMS";
    window.scrollTo(0, 0);
    this.getCustomer();
  }

  getCustomer = async () => {
    await customerApi.get().then(data => {
      if (Array.isArray(data.data)) {
        let customerList = [];

        data.data.forEach((val) => {
          customerList.push({
            value: val.id,
            label: val.company_code,
            name: val.company_name
          });
        });

        this.setState({
          customerList: customerList
        });
      } else {
        apiUtil.toast('Failed to get customer data.', 'error');
      }
    }).catch(error => console.log(error));
  }

  handleLocationChange = async event => {
    this.setState({
      zone_id: event.value
    });

    if (this.state.selectedCustomer.id === 0) return false;
    
    await deliveryOrderApi.scan({
      customer_id: this.state.selectedCustomer.id,
      zone_id: event.value,
      start_date: moment(this.state.dateRange.startDate).format('YYYY-MM-DD'),
      end_date: moment(this.state.dateRange.endDate).format('YYYY-MM-DD'),
      reference_no: ""
    }, 'get').then(res => {
      if (res.status !== true) {
        apiUtil.toast(res.error, 'error');
        return false;
      }
      let orderRefList = [];
      if (res.order.length > 0) {
        res.order.forEach(order => {
          orderRefList.push({
            value: order.reference_no,
            label: order.reference_no
          });
        });
      }

      console.log(orderRefList);
      
      this.setState({
        orderRefList: orderRefList
      });
    }).catch(error => console.log(error));
  }

  handleCustomerChange = async event => {
    this.setState({
      selectedCustomer: {
        id: event.value,
        name: event.name
      }
    });

    let zone = this.state.zone_id;
    if (this.state.zone_id === 0) { zone = null;}

    await deliveryOrderApi.scan({
      customer_id: event.value,
      zone_id: zone,
      start_date: moment(this.state.dateRange.startDate).format('YYYY-MM-DD'),
      end_date: moment(this.state.dateRange.endDate).format('YYYY-MM-DD'),
      reference_no: ""
    }, 'get').then(res => {
      if (res.status !== true) {
        apiUtil.toast(res.error, 'error');
        return false;
      }
      let orderRefList = [];
      if (res.order.length > 0) {
        res.order.forEach(order => {
          orderRefList.push({
            value: order.reference_no,
            label: order.reference_no
          });
        });
      }
      this.setState({
        orderRefList: orderRefList
      });
    }).catch(error => console.log(error));
  }

  handleOrderChange = async event => {
    this.setState({
      ref_no: event.value
    });

    let zone = this.state.zone_id;
    if (this.state.zone_id === 0) { zone = null;}
    
    await deliveryOrderApi.scan({
      customer_id: this.state.selectedCustomer.id,
      zone_id: zone,
      start_date: moment(this.state.dateRange.startDate).format('YYYY-MM-DD'),
      end_date: moment(this.state.dateRange.endDate).format('YYYY-MM-DD'),
      reference_no: event.value
    }, 'get').then(res => {
      let itemList = [];
      let totalQty = 0;



      if (res.order_details.length > 0) {
        res.order_details.forEach(item => {
          let location = '';
          locationData.map(function(v,k){ if(v.value==item.location){ location = v.label;} });
          itemList.push({
            id: item.id,
            part_no: item.part_no,
            lot_no: item.lot_no,
            serial_no: item.serial_no,
            remarks: item.remarks,
            location: location,
            quantity: item.quantity,
            quantity_scanned: item.quantity_scanned
          });

          totalQty = totalQty + item.quantity;
        });
      }

      this.setState({
        totalQty: totalQty,
        items: itemList
      });
    }).catch(res => {
      apiUtil.toast(res.message, 'error');
    });
  }

  handleScanFieldChange = event => {
    let scannedField = this.state.scannedField;
    scannedField[event.target.name] = event.target.value;
    this.setState({scannedField: scannedField});
  }
  
  scanItem = event => {
    if (event.keyCode === 13) {
      let scannedField = this.state.scannedField;
      let itemList = this.state.items;
      let totalQtyScanned = this.state.totalQtyScanned;
      let itemScanned = null;

      scannedField[event.target.name] = event.target.value;

      if (scannedField.lot_no !== '' && scannedField.part_no !== '') {
        if (itemList.length > 0) {
          itemList.forEach((item, index) => {
            if (
              item.part_no === scannedField.part_no &&
              item.lot_no === scannedField.lot_no
            ) {
              itemScanned = item;
              itemList[index]['quantity_scanned'] = item.quantity_scanned + 1;
              totalQtyScanned = totalQtyScanned + 1;
            }
          });
        }
      }

      if (itemScanned === null) {
        apiUtil.toast('Item not found!', 'error');
      } else {
        this.submitScan(itemScanned);
      }

      this.setState({
        scannedField: scannedField,
        items: itemList,
        totalQtyScanned: totalQtyScanned
      });
    }
  }

  submitScan = async (item) => {
    await deliveryOrderApi.scan({
      customer_id: this.state.selectedCustomer.id,
      reference_no: this.state.ref_no,
      data: [
        {
          'id': item.id,
          'part_no': item.part_no,
          'lot_no': item.lot_no,
          'serial_no': item.serial_no
        }
      ]
    }, 'post').then(res => {
      console.log(res);
    }).catch(error => console.log(error));
  }

  render() {
    return (
      <Box className="dashboard-page">
        <StickyPageHeader>
          <BreadCrumb />
          <h1 className="page-title">Check Out Delivery Order</h1>
        </StickyPageHeader>

        <Row className="mt-3">
          <Col md="8">
            <div className="mb-4">
              <Label className="control-label">
                Choose Company Code*
              </Label>
              <Select className="form-input has-margin no-border" options={this.state.customerList} onChange={(e) => this.handleCustomerChange(e)} />
            </div>

            <div className="mb-4">
              <Label className="control-label">
                Company Name
              </Label>
              <Input className="form-control has-margin form-input" defaultValue={this.state.selectedCustomer.name} disabled="" readOnly="readonly" />
            </div>

            <div className="mb-4">
              <Label className="control-label">
                Location
              </Label>
              <Select className="form-input has-margin no-border" options={locationData} onChange={(e) => this.handleLocationChange(e)} />
            </div>

            <div className="mb-4">
              <Label className="control-label">
                Delivery Order No.
              </Label>
              <Select className="form-input has-margin no-border" options={this.state.orderRefList} onChange={(e) => this.handleOrderChange(e)} />
            </div>

            <div className="mb-4 clearfix">
              <Label className="control-label">
                Part No.
              </Label>
              <Input type="text" className="form-input" name="part_no" onKeyDown={(e) => this.scanItem(e)} onChange={(e) => this.handleScanFieldChange(e)} />
            </div>

            <div className="mb-4 clearfix">
              <Label className="control-label">
                Lot No.
              </Label>
              <Input type="text" className="form-input" name="lot_no" onKeyDown={(e) => this.scanItem(e)} onChange={(e) => this.handleScanFieldChange(e)} />
            </div>

            <div className="mb-4 clearfix">
              <Label className="control-label">
                Date
              </Label>
              <CustomDateRangePicker
                className="form-input" 
                range={this.state.dateRange}
                onChange={(range) => {
                  this.setState({
                    dateRange: range,
                  });
                }}
                toggle=""
              />
            </div>
          </Col>
        </Row>

        <Row className="mt-3">
          <Col xs="12">
            <Card>
              <CardTitle>Item List</CardTitle>
              <CardBody>
                <Table>
                  <thead>
                    <tr>
                      <th>Part No.</th>
                      <th>Lot No.</th>
                      <th>Serial No.</th>
                      <th>Remarks</th>
                      <th>Location</th>
                      <th>Qty</th>
                      <th>Qty Scanned</th>
                    </tr>
                  </thead>

                  <tbody>
                    {
                      this.state.items.map((item, index) => {
                        return (<tr key={index}>
                          <td>{item.part_no}</td>
                          <td>{item.lot_no}</td>
                          <td>{item.serial_no}</td>
                          <td>{item.remarks}</td>
                          <td>{item.location}</td>
                          <td>{item.quantity}</td>
                          <td><Input type="number" readOnly="" className="theme-input-color text-right font-weight-bold" value={item.quantity_scanned} /></td>
                        </tr>);
                      })
                    }
                    <tr className="font-weight-bold">
                      <td colSpan="5" align="right">Total Quantity</td>
                      <td>{this.state.totalQty}</td>
                      <td><Input type="number" value={this.state.totalQtyScanned} readOnly="" className="text-right font-weight-bold" /></td>
                    </tr>
                  </tbody>

                  {/*<tfoot>
                    <tr>
                      <td colSpan="7">
                        <ProgressButton
                          className="float-left" 
                          type={'button'}
                          onClick={() => this.submitScan()}
                          color={'secondary'}
                          isLoading={this.state.isLoading}>
                          <Box pl={2} pr={2}>Scan Complete</Box>
                        </ProgressButton>
                      </td>
                    </tr>
                  </tfoot>*/}
                </Table>
              </CardBody>
            </Card>
          </Col>
        </Row>
     </Box>
    );
  }
}

export default DeliveryCheckForm;