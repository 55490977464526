import React, { useEffect, useState } from 'react';

import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';

import Signin from '../../views/Account/Signin';

import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

import api from "../../api/api";
import apiUtil from "../../api/apiUtil";

const theme = createMuiTheme({
  /* RESPONSIVE -> It is also necessary to change at _mixins.scss*/
  breakpoints: {
    values: {
      xs: 576, 
      sm: 768, 
      md: 992, 
      lg: 1200, 
      xl: 1366
    }
  },
  palette: {
    primary: {
      light: '#83d6d3',
      main: '#64CCC9',
      dark: '#468e8c',
      contrastText: '#fff',
    },
    secondary: {
      light: '#3f464c',
      main: '#101820',
      dark: '#0b1016',
      contrastText: '#fff',
    }
  }
});

export const AuthContext = React.createContext();

const AuthDataProvider = ({ children }) => {
  const prevAuth = window.localStorage.getItem('authenticated') || false;
  const prevAccessToken = window.localStorage.getItem('access_token') || null;
  const prevRefreshToken = window.localStorage.getItem('refresh_token') || null;
  const prevTokenType = window.localStorage.getItem('token_type') || null;
  const prevToken = window.localStorage.getItem('token') || null;
  const prevUser = window.localStorage.getItem('user') || null;

  const [authenticated, setAuthenticated] = useState(prevAuth);
  const [accessToken, setAccessToken] = useState(prevAccessToken);
  const [refreshToken, setRefreshToken] = useState(prevRefreshToken);
  const [tokenType, setTokenType] = useState(prevTokenType);
  const [token, setToken] = useState(prevToken);
  const [user, setUser] = useState(prevUser);
  const [tokenCookie, setTokenCookie] = useState(false);

  const clearStorage = () => {
    setAuthenticated(false);
    setAccessToken('');
    setRefreshToken('');
    setTokenType('');
    setToken(null);
    setUser(null);
    window.localStorage.clear();
    apiUtil.deleteCookie("worknode_access_token");
    apiUtil.deleteCookie("worknode_refresh_token");
    apiUtil.deleteCookie("worknode_token_type");
    apiUtil.deleteCookie("worknode_token");
    apiUtil.deleteCookie("worknode_user");
  };

  useEffect(
    () => {
      const loginFromCookie = (accessToken) => {
        api.read('profile/admins', {}, accessToken).then(result => {
          apiUtil.parseResult(result, data => {
            window.localStorage.setItem('authenticated', true);
            window.localStorage.setItem('access_token', accessToken);

            let refreshToken = apiUtil.getCookie('worknode_refresh_token');
            if (refreshToken !== null) {
              window.localStorage.setItem('refresh_token', refreshToken);
            }

            let tokenType = apiUtil.getCookie('worknode_token_type');
            if (tokenType !== null) {
              window.localStorage.setItem('token_type', tokenType);
            }

            let token = apiUtil.getCookie('worknode_token');
            if (token !== null) {
              window.localStorage.setItem('token', token);
            }

            let user = {
              status: true,
              token: token,
              owner_info: data.result,
              result: data.result
            };

            window.localStorage.setItem('user', JSON.stringify(user));
            window.location.replace("/");
          });

        }, (error, type) => {
        });
      }

      if (authenticated) {
        window.localStorage.setItem('authenticated', authenticated);
        window.localStorage.setItem('access_token', accessToken);
        window.localStorage.setItem('refresh_token', refreshToken);
        window.localStorage.setItem('token_type', tokenType);
        window.localStorage.setItem('token', token);
        window.localStorage.setItem('user', user);

        if (!tokenCookie && (accessToken !== null && refreshToken !== null && tokenType !== null && token !== null && user !== null)) {
          apiUtil.setCookie("worknode_access_token", accessToken);
          apiUtil.setCookie("worknode_refresh_token", refreshToken);
          apiUtil.setCookie("worknode_token_type", tokenType);
          apiUtil.setCookie("worknode_token", token);
          window.localStorage.setItem('has_logged_in_cookie', true);
          setTokenCookie(true);
        }
      } else {
        let accessTokenCookie = apiUtil.getCookie("worknode_access_token");
        let checkLoginCookie = window.localStorage.getItem('has_logged_in_cookie') || null;

        if (accessTokenCookie !== null && checkLoginCookie === null) {
          loginFromCookie(accessTokenCookie);
        }

        window.localStorage.removeItem('authenticated');
        window.localStorage.removeItem('access_token');
        window.localStorage.removeItem('refresh_token');
        window.localStorage.removeItem('token');
        window.localStorage.removeItem('user');
      }
    },
    [authenticated, token, accessToken, refreshToken, tokenType, user, tokenCookie]
  );

  const defaultContext = {
    clearStorage,
    authenticated,
    setAuthenticated,
    token,
    setToken,
    accessToken,
    setAccessToken,
    refreshToken,
    setRefreshToken,
    tokenType,
    setTokenType,
    user,
    setUser
  };

  return (
    <MuiThemeProvider theme={theme}>
      <AuthContext.Provider value={defaultContext}>
        {
          authenticated
          ?
          children
          :
          <div className="App">
            <BrowserRouter>
              <Switch>
                <Route exact path="/">
                  <Redirect to={"/sign-in"} />
                </Route>
                <Route path="/sign-in" component={Signin} />
                <Route component={Signin} />
              </Switch>
            </BrowserRouter>
          </div>
        }
      </AuthContext.Provider>
    </MuiThemeProvider>
  );
};

export default AuthDataProvider;
