import React from 'react';

import {
  Box,
  Popper,
  Grow,
  ClickAwayListener
} from '@material-ui/core';


const CustomDropdown = props => {
  const { children, element, className } = props;

  const [open, setOpen] = React.useState((props.open === true || props.open === false) ? props.open : false);
  const [disablePortal, setDisablePortal] = React.useState((props.disablePortal === true || props.disablePortal === false) ? props.disablePortal : true);

  const anchorRef = React.useRef(null);
  const handleToggle = (e) => {
    e.preventDefault();
    e.stopPropagation();

    setOpen(open => !open);

    if(props.onChange){
      props.onChange(open);
    }
  };
  
  const handleClose = event => {
    setOpen(false);
    if(props.onChange){
      props.onChange(open);
    }
  };

  React.useEffect(() => {
    setOpen(((props.open === true || props.open === false) ? props.open : false));
    setDisablePortal(((props.disablePortal === true || props.disablePortal === false) ? props.disablePortal : true));
  }, [props.open, props.disablePortal]);

  if(children) {
    return (
      <Box position={'relative'}>
        <Box
        ref={anchorRef}
        aria-haspopup="true"
        onClick={handleToggle}
        >
          {element}
        </Box>
        <Popper open={open} 
        anchorEl={anchorRef.current} 
        transition
        disablePortal={disablePortal}
        className={className}
        >
          <Grow>
            <ClickAwayListener onClickAway={handleClose} mouseEvent={'onClick'}>
              <Box position={'relative'}>{children}</Box>
            </ClickAwayListener>
          </Grow>
        </Popper>
      </Box>
      );
  } else {
    return null;
  }
}

export default CustomDropdown;