import React from 'react';

import {
  Row,
  Col,
  Card,
  CardTitle,
  CardBody,
  Label,
  Table
} from "reactstrap";

import IconButton from "../Button/IconButton";
import { Field } from "formik";

export default class DepartmentForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      contacts: this.props.contacts === undefined ? [] : this.props.contacts
    };
  }

  addContact = element => {
    this.props.addContactToState(this.props.index);
  }

  removeContact = element => {
    element.currentTarget.parentNode.parentNode.remove();
  }

  render() {
    const theClass = this;

    return (
      <>    
        <Col sm="6" className="mt-3">
          <Card>
            <CardTitle>
              <h4>
                Department #{this.props.index + 1}
                <IconButton className="simple float-right" icon="remove" onClick={() => this.props.handleremove(this.props.index)} title="Remove Department" />
              </h4>
            </CardTitle>

            <CardBody className="pt-0">
              <Row>
                <Col sm="12" className="mt-3">
                  <Label>
                    Department Name*
                  </Label>
                  <Field className="form-control" name={'department_name_' + this.props.index} defaultValue={this.props.name} onChange={(e) => theClass.props.handleinputchange(this.props.index, 'department_name', e.target.value)}  />
                </Col>

                <Col sm="12" className="mt-3">
                  <Label>
                    Contact Person*
                  </Label>
                  <Table>
                    <thead>
                      <tr>
                        <th>Contact Name</th>
                        <th>Phone Number</th>
                        <th>Email</th>
                        <th>Remove</th>
                      </tr>
                    </thead>

                    <tbody>
                      {this.state.contacts.map(function (contact, index) {
                        let uniqueIndex = theClass.props.index + '_' + index;

                        return <tr key={index}>
                          <td><Field className="form-control" name={'contact_name_' + uniqueIndex} defaultValue={contact.first_name + ' ' + contact.last_name} onChange={(e) => theClass.props.handleinputchange(theClass.props.index, 'contact_persons.name', e.target.value, index)} /></td>
                          <td><Field className="form-control" name={'contact_phone_' + uniqueIndex} defaultValue={contact.contact_no} onChange={(e) => theClass.props.handleinputchange(theClass.props.index, 'contact_persons.contact_no', e.target.value, index)}  /></td>
                          <td><Field className="form-control" name={'contact_email_' + uniqueIndex} defaultValue={contact.email} onChange={(e) => theClass.props.handleinputchange(theClass.props.index, 'contact_persons.email', e.target.value, index)}  /></td>
                          <td><IconButton className="simple float-right" icon="remove" onClick={theClass.removeContact} title="Remove Department" /></td>
                        </tr>
                      })}
                    </tbody>

                    <tfoot>
                      <tr>
                        <td colSpan="4">
                          <a href="#a" onClick={this.addContact}>Add Contact Person</a>
                        </td>
                      </tr>
                    </tfoot>
                  </Table>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </>
    )
  }
}